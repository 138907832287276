import React from 'react';
import 'bulma/css/bulma.css'
import './css/style.css'
import { connect } from 'react-redux'
import { setLocale } from 'react-redux-i18n'
import 'react-id-swiper/lib/styles/css/swiper.css'
const locale = require('react-redux-i18n').I18n


const iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.054025975456!2d101.37665761487736!3d17.457128088036924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3121276b967de40b%3A0xd3ff6cebc2f995dc!2z4Lig4Li54LmA4Lij4Li34Lit4Lia4Li44Lip4Lia4Liy4Lij4Li14Liq4Lit4Lij4LmM4LiXIOC5geC4reC4meC4lOC5jCDguKrguJvguLI!5e0!3m2!1sth!2sth!4v1574660601271!5m2!1sth!2sth" class="map_size"  frameborder="0" style="border:0;" allowfullscreen=""></iframe>';

function Iframe(props) {
    return (<div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} />);
}
function Contact() {

    return (
        <div className="page_contain">
            <div className="columns is-mobile is-centered is-multiline is-mobile">
                <div className="column is-12-mobile is-9-desktop">
                    <div className="page_content">
                        <div className="icon_info">
                            <img src="images/home.png" alt="" />
                        </div>
                        <h1 className="title_info">
                            {locale.t('ContactUs')}
                        </h1>
                        <p className="text_info">
                        </p>
                        <div className=" section_view">
                            {/* Activities 1 */}
                            <div className="columns    is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <div className="map_contain">
                                        <Iframe iframe={iframe} />
                                    </div>

                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <div className="contact_info">
                                        <p>Phurua Bussaba Resort
                                         </p>
                                        <p>
                                            {locale.t('contact_1')}
                                        </p>
                                        <p>Tel: 042-899299, 042-899330, 082-3942619
                                         </p>
                                        <p>Email : bussabaresort@gmail.com , khingkharn_t@hotmail.com</p>
                                        <p>Line id : bussabaresort1
                                         </p>
                                        <a href="https://www.facebook.com/phuruabussaba" target="_blank" className="social_link is-inline">
                                            <img src="images/facebook.png" alt="" />
                                        </a>
                                        <a href="http://line.me/ti/p/~bussabaresort1" target="_blank" className="social_link is-inline">
                                            <img src="images/line.png" alt="" />
                                        </a>
                                        <a href="https://goo.gl/maps/aGrSvAH4VnDVsRp5A" target="_blank" className="social_link is-inline">
                                            <img src="images/google.png" alt="" />
                                        </a>
                                        <a href="https://m.youtube.com/channel/UCe4T-55WEZK9NilftsNzmIg?reload=9" target="_blank" className="social_link is-inline">
                                            <img src="images/youtube.png" alt="" />
                                        </a>
                                        <hr />
                                        <h1 className="title_footer">{locale.t('RentTitle')}</h1>
                                        <p>
                                            {locale.t('RentTitleA')}
                                        </p>
                                        <p>
                                            {locale.t('RentTitleB')}
                                        </p>
                                        <p>
                                            {locale.t('RentTitleC')}
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    l: state.i18n.locale,
})

const mapDispatchToProps = dispatch => ({
    setLang: (l) => {
        dispatch(setLocale(l))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Contact);

