import React from 'react';
import 'bulma/css/bulma.css'
import './css/style.css'
import { connect } from 'react-redux'
import { setLocale } from 'react-redux-i18n'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/lib/styles/css/swiper.css'
const locale = require('react-redux-i18n').I18n
const params = {
    effect: 'flip',
    grabCursor: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    }
}
function Restroom() {
    return (
        <div className="page_contain">
            <div className="columns is-mobile is-centered is-multiline is-mobile">
                <div className="column is-12-mobile is-9-desktop">
                    <div className="page_content">
                        <div className="icon_info">
                            <img src="images/ic_house.png" alt="" />
                        </div>
                        <h1 className="title_info">
                            {locale.t('Restroom')}
                        </h1>
                        <p className="text_info">
                            {locale.t('textInfo')}
                        </p>
                        <div className=" section_view">
                            {/* Room 1 */}
                            <div className="columns   is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <Swiper {...params}>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room1/standard1.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room1/standard2.jpeg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room1/standard3.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room1/standard4.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room1/standard5.jpg" alt="" />
                                            </div>
                                        </div>
                                    </Swiper>
                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('SubMenuA')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('Room1Details')}
                                    </p>
                                    <p className="text_price">
                                        {locale.t('Room1Price1')}
                                    </p>
                                    <p className="text_price">
                                        {locale.t('Room1Price2')}
                                    </p>
                                </div>
                            </div>
                            {/* Room 2 */}
                            <div className="columns is-reverse-mobile is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('Room2Title')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('Room2Details')}
                                    </p>
                                    <p className="text_price">
                                        {locale.t('Room2Price1')}
                                    </p>
                                    <p className="text_price">
                                        {locale.t('Room2Price2')}
                                    </p>
                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <Swiper {...params}>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room2/bungalow1.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room2/bungalow2.jpeg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room2/bungalow3.jpeg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room2/bungalow4.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room2/bungalow5.jpg" alt="" />
                                            </div>
                                        </div>
                                    </Swiper>
                                </div>
                            </div>
                            {/* Room 3 */}
                            <div className="columns   is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <Swiper {...params}>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room3/cabins1.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room3/cabins2.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room3/cabins3.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room3/cabins4.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room3/cabins5.jpg" alt="" />
                                            </div>
                                        </div>
                                    </Swiper>
                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('Room3Title')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('Room3Details')}
                                    </p>
                                    <p className="text_price">
                                        {locale.t('Room3Price1')}
                                    </p>
                                    <p className="text_price">
                                        {locale.t('Room3Price2')}
                                    </p>
                                </div>
                            </div>
                            {/* Room 4 */}
                            <div className="columns is-reverse-mobile is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('Room4Title')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('Room4Details')}
                                    </p>
                                    <p className="text_price">
                                        {locale.t('Room4Price1')}
                                    </p>
                                    <p className="text_price">
                                        {locale.t('Room4Price2')}
                                    </p>
                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <Swiper {...params}>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room4/bighome1.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room4/bighome2.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room4/bighome3.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room4/bighome4.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room4/bighome5.jpg" alt="" />
                                            </div>
                                        </div>
                                    </Swiper>
                                </div>
                            </div>
                            {/* Room 5*/}
                            <div className="columns   is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <Swiper {...params}>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room5/deluxe1.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room5/deluxe2.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room5/deluxe3.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room5/deluxe4.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room5/deluxe5.jpg" alt="" />
                                            </div>
                                        </div>
                                    </Swiper>
                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('Room5Title')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('Room5Details')}
                                    </p>
                                    <p className="text_price">
                                        {locale.t('Room5Price1')}
                                    </p>
                                    <p className="text_price">
                                        {locale.t('Room5Price2')}
                                    </p>
                                </div>
                            </div>
                            {/* Room 6 */}
                            <div className="columns is-reverse-mobile is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('Room6Title')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('Room6Details')}
                                    </p>
                                    <p className="text_price">
                                        {locale.t('Room6Price1')}
                                    </p>
                                    <p className="text_price">
                                        {locale.t('Room6Price2')}
                                    </p>
                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <Swiper {...params}>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room6/honey1.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room6/honey2.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room6/honey3.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room6/honey4.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room6/honey5.jpg" alt="" />
                                            </div>
                                        </div>
                                    </Swiper>
                                </div>
                            </div>
                            {/* Room 7*/}
                            <div className="columns   is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <Swiper {...params}>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room7/mangkang2.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room7/mangkang1.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room7/mangkang3.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room7/mangkang4.jpg" alt="" />
                                            </div>
                                        </div>

                                    </Swiper>
                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('Room7Title')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('Room7Details')}
                                    </p>

                                </div>
                            </div>
                            {/* Room 8 */}
                            <div className="columns is-reverse-mobile is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('Room8Title')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('Room8Details')}
                                    </p>

                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <Swiper {...params}>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room8/floor1.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room8/floor2.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room8/floor3.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room8/floor4.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room8/floor5.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room8/floor6.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room8/floor7.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room8/floor8.jpg" alt="" />
                                            </div>
                                        </div>

                                    </Swiper>
                                </div>
                            </div>
                            {/* Room 9*/}
                            <div className="columns   is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <Swiper {...params}>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room9/out1.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room9/out2.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room9/out3.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room9/out4.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/room/room9/out5.jpg" alt="" />
                                            </div>
                                        </div>
                                    </Swiper>
                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('Room9Title')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('Room9Details')}
                                    </p>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    l: state.i18n.locale,
})

const mapDispatchToProps = dispatch => ({
    setLang: (l) => {
        dispatch(setLocale(l))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Restroom);

