import React, { Component } from 'react'
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import { setLocale } from 'react-redux-i18n'
const locale = require('react-redux-i18n').I18n

class Header extends Component {

    handleChangLanguage = (l) => {
        if (this.props.l !== l) {
            this.props.setLang(l)
        }

    }

    render() {
        return (
            <nav className="navbar is-fixed-top">
                <div className="container">
                    <div className="navbar-brand">
                        <Link to="/">
                            <img src="images/logo.png" className="logo" alt="" />
                        </Link>
                        <div className="navbar-burger burger" data-target="navbar" aria-label="menu" aria-expanded="false">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>

                    <div id="navbar" className="navbar-menu">
                        <div className="navbar-start navbar_center">

                            <a className="navbar-item" href="/">
                                {locale.t('Home')}
                            </a>
                            <a className="navbar-item" href="/about">
                                {locale.t('About')}
                            </a>
                            <a className="navbar-item" href="/restroom">
                                {locale.t('Restroom')}
                            </a>
                            <a className="navbar-item" href="/services">
                                {locale.t('Catering')}
                            </a>
                            <a className="navbar-item" href="/meetroom">
                                {locale.t('Meeting')}
                            </a>

                            <a className="navbar-item" href="/activities">
                                {locale.t('Activities')}
                            </a>
                            <a className="navbar-item" href="/travel">
                                {locale.t('Travel')}
                            </a>
                            <a className="navbar-item" href="/contact">
                                {locale.t('ContactUs')}
                            </a>
                        </div>
                        <div className="navbar-end ">
                            <div className="navbar-item set_language_icon">

                                <div className="is-inline">
                                    <img className="language_icon" onClick={() => this.handleChangLanguage('th')} src="images/th.png" alt="" />
                                    <span className="set_vertical">|</span>
                                    <img className="language_icon" onClick={() => this.handleChangLanguage('en')} src="images/us.png" alt="" />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </nav>
        );
    }

}

const mapStateToProps = state => ({
    l: state.i18n.locale,
})

const mapDispatchToProps = dispatch => ({
    setLang: (l) => {
        dispatch(setLocale(l))
    },
})



document.addEventListener('DOMContentLoaded', function () {

    // Get all "navbar-burger" elements
    var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

        // Add a click event on each of them
        $navbarBurgers.forEach(function ($el) {
            $el.addEventListener('click', function () {

                // Get the target from the "data-target" attribute
                var target = $el.dataset.target;
                var $target = document.getElementById(target);

                // Toggle the class on both the "navbar-burger" and the "navbar-menu"
                $el.classList.toggle('is-active');
                $target.classList.toggle('is-active');
            });
        });

    }
});




export default connect(mapStateToProps, mapDispatchToProps)(Header);
