import React from 'react';
import 'bulma/css/bulma.css'
import './css/style.css'
import { connect } from 'react-redux'
import { setLocale } from 'react-redux-i18n'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/lib/styles/css/swiper.css'
const locale = require('react-redux-i18n').I18n
const params = {
    effect: 'flip',
    grabCursor: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    }
}
function Activites() {
    return (
        <div className="page_contain">
            <div className="columns is-mobile is-centered is-multiline is-mobile">
                <div className="column is-12-mobile is-9-desktop">
                    <div className="page_content">
                        <div className="icon_info">
                            <img src="images/ic_tree.png" alt="" />
                        </div>
                        <h1 className="title_info">
                            {locale.t('Activities')}
                        </h1>
                        <p className="text_info">
                        </p>
                        <div className=" section_view">
                            {/* Activities 1 */}
                            <div className="columns   is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <Swiper {...params}>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/route21/route1.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/route21/route2.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/route21/route3.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/route21/route4.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/route21/route5.jpg" alt="" />
                                            </div>
                                        </div>
                                    </Swiper>
                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('Activities1')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('Activities1Details')}
                                    </p>

                                </div>
                            </div>
                            {/* Activities 2 */}
                            <div className="columns is-reverse-mobile is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('Activities2')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('Activities2Details')}
                                    </p>

                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <Swiper {...params}>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/sufficient/sufficient1.jpg" alt="" />
                                            </div>
                                        </div>

                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/sufficient/sufficient3.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/sufficient/sufficient4.jpg" alt="" />
                                            </div>
                                        </div>

                                    </Swiper>
                                </div>
                            </div>
                            {/* Activities 3 */}
                            <div className="columns   is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <Swiper {...params}>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/otop/otop1.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/otop/otop2.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/otop/otop3.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/otop/otop4.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/otop/otop5.jpg" alt="" />
                                            </div>
                                        </div>
                                    </Swiper>
                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('Activities3')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('Activities3Details')}
                                    </p>

                                </div>
                            </div>
                            {/* Activities 4 */}
                            <div className="columns is-reverse-mobile is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('Activities4')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('Activities4Details')}
                                    </p>

                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <Swiper {...params}>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/massage/massage1.jpg" alt="" />
                                            </div>
                                        </div>
                                    </Swiper>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    l: state.i18n.locale,
})

const mapDispatchToProps = dispatch => ({
    setLang: (l) => {
        dispatch(setLocale(l))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Activites);

