import React from 'react';
import 'bulma/css/bulma.css'
import './css/style.css'
import { connect } from 'react-redux'
import { setLocale } from 'react-redux-i18n'
import 'react-id-swiper/lib/styles/css/swiper.css'
const locale = require('react-redux-i18n').I18n

function Travel() {
    return (
        <div className="page_contain">
            <div className="columns is-mobile is-centered is-multiline is-mobile">
                <div className="column is-12-mobile is-9-desktop">
                    <div className="page_content">
                        <div className="icon_info">
                            <img src="images/ic_tree.png" alt="" />
                        </div>
                        <h1 className="title_info">
                            {locale.t('Travel')}
                        </h1>
                        <p className="text_info">
                        </p>
                        <div className=" section_view">
                            {/* Activities 1 */}
                            <div className="columns   is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <div className="card_img_info">
                                        <img src="images/travel/top/top1.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('travel11')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('travel11_info')}
                                    </p>

                                </div>
                            </div>
                            {/* Activities 2 */}
                            <div className="columns is-reverse-mobile is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('travel19')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('travel19_info')}
                                    </p>

                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <div className="card_img_info">
                                        <img src="images/travel/shadow/shadow1.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                            {/* Activities 3 */}
                            <div className="columns   is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <div className="card_img_info">
                                        <img src="images/travel/PhiTaKhon/PhiTaKhon1.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('travel20')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('travel20_info')}
                                    </p>

                                </div>
                            </div>
                            {/* Activities 4 */}
                            <div className="columns is-reverse-mobile is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('travel17')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('travel17_info')}
                                    </p>

                                </div>
                                <div className="column is-12-mobile is-6-desktop">

                                    <div className="card_img_info">
                                        <img src="images/travel/PlaBa/PlaBa1.jpg" alt="" />
                                    </div>

                                </div>
                            </div>

                            {/* Activities 5 */}
                            <div className="columns   is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <div className="card_img_info">
                                        <img src="images/travel/Somdet/Somdet1.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('travel15')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('travel15_info')}
                                    </p>

                                </div>
                            </div>
                            {/* Activities 2 */}
                            <div className="columns is-reverse-mobile is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        {locale.t('travel8')}
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('travel8_info')}
                                    </p>

                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <div className="card_img_info">
                                        <img src="images/travel/kratingz/kratingz1.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    l: state.i18n.locale,
})

const mapDispatchToProps = dispatch => ({
    setLang: (l) => {
        dispatch(setLocale(l))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Travel);

