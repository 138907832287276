import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import 'bulma/css/bulma.css'
import './css/style.css'
import Header from "./Header";
import Home from './Home';
import Restroom from './Restroom';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';
import Activities from "./Activities";
import Services from "./Services";
import About from "./About";
import Travel from "./Travel";
import Meetroom from "./Meetroom";
import Contact from "./Contact";



function App() {
  return (
    <Router>
      <ScrollToTop>

        <Header />
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/restroom" component={Restroom} />
        <Route path="/activities" component={Activities} />
        <Route path="/services" component={Services} />
        <Route path="/travel" component={Travel} />
        <Route path="/meetroom" component={Meetroom} />
        <Route path="/contact" component={Contact} />
        <Footer />

      </ScrollToTop>
    </Router>
  )
}


export default App;
