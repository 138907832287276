import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import 'bulma/css/bulma.css'
import './css/style.css'
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import { setLocale } from 'react-redux-i18n'
import 'react-id-swiper/lib/styles/css/swiper.css'
const locale = require('react-redux-i18n').I18n


class Home extends Component {

    render() {

        const bannerSlide = {

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            }
        }
        const Meet = {
            ContainerEl: 'section',
            slidesPerView: 2,
            slidesPerColumn: 2,
            spaceBetween: 15,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {

                1024: {
                    slidesPerView: 2,
                    slidesPerColumn: 2
                },
                768: {
                    slidesPerView: 1.2,
                    slidesPerColumn: 1

                },
                640: {
                    slidesPerView: 1.2,
                    slidesPerColumn: 1,

                },
                320: {
                    slidesPerView: 1.2,
                    slidesPerColumn: 1

                }
            }
        }
        const Card = {
            ContainerEl: 'section',
            slidesPerView: 4,
            slidesPerColumn: 1,
            spaceBetween: 15,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {

                1024: {
                    slidesPerView: 3,
                    slidesPerColumn: 1
                },
                768: {
                    slidesPerView: 2,
                    slidesPerColumn: 1

                },
                640: {
                    slidesPerView: 1.2,
                    slidesPerColumn: 1,

                },
                320: {
                    slidesPerView: 1.2,
                    slidesPerColumn: 1

                }
            }
        }

        return (
            <div>
                <div className="banner_contain">
                    <Swiper  {...bannerSlide}>
                        <div>
                            <div className="banner">
                                <div className="cover_img img_a"></div>
                            </div>
                        </div>
                        <div>
                            <div className="banner">
                                <div className="cover_img img_b"></div>
                            </div>
                        </div>
                        <div >
                            <div className="banner">
                                <div className="cover_img img_c"></div>
                            </div>
                        </div>
                        <div >
                            <div className="banner">
                                <div className="cover_img img_d"></div>
                            </div>
                        </div>
                    </Swiper>
                    <div className="caption_banner">

                        <h1>{locale.t('CaptionTitle')}</h1>
                        <h3>{locale.t('CaptionDetails')}</h3>
                    </div>
                </div>

                <section className="about">
                    <div className="container">
                        <div className="columns is-vcentered is-multiline is-mobile is-variable is-4">
                            <div className="column is-12-mobile is-6-desktop">
                                <div className="card_about is_fixpadding">
                                    <img src="images/post.jpg" className="img_cover" alt="" />
                                </div>
                            </div>
                            <div className="is-paddingtopless column is-12-mobile is-6-desktop">
                                <div className="card_about -card_about_right">
                                    <div className="about_text">
                                        <h1 className="title_about">{locale.t('About')}</h1>
                                        <p className="text_about">
                                            {locale.t('textAbout')}
                                        </p>
                                        <div className="see_about">
                                            <Link to="/about" className="button is-more">
                                                {locale.t('seeMore')}
                                            </Link>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="my_room">
                    <div className="container">
                        <div className="icon_info">
                            <img src="images/ic_house.png" alt="" />
                        </div>
                        <h1 className="title_my_room">
                            {locale.t('Restroom')}
                        </h1>
                        <Swiper  {...Card}>
                            <div>
                                <article className="cards card--1">
                                    <div className="card__img"></div>
                                    <div className="card__img--hover"></div>
                                    <div className="card__info">
                                        <h3 className="card__title"> {locale.t('SubMenuB')}</h3>

                                    </div>
                                </article>
                            </div>
                            <div>
                                <article className="cards card--2">
                                    <div className="card__img"></div>
                                    <div className="card__img--hover"></div>
                                    <div className="card__info">
                                        <h3 className="card__title">{locale.t('SubMenuD')}</h3>

                                    </div>
                                </article>
                            </div>
                            <div>
                                <article className="cards card--3">
                                    <div className="card__img"></div>
                                    <div className="card__img--hover"></div>
                                    <div className="card__info">
                                        <h3 className="card__title">{locale.t('SubMenuA')}</h3>

                                    </div>
                                </article>
                            </div>
                            <div>
                                <article className="cards card--4">
                                    <div className="card__img"></div>
                                    <div className="card__img--hover"></div>
                                    <div className="card__info">
                                        <h3 className="card__title">{locale.t('SubMenuJ')}</h3>

                                    </div>
                                </article>
                            </div>
                        </Swiper>
                        <div className="see_all">
                            <Link to="/restroom" className="button   is-more">
                                {locale.t('SeeAll')}
                            </Link>
                        </div>
                    </div>
                </section>

                <section className="info_room">
                    <div className="container">
                        <div className="columns  is-vcentered is-multiline is-mobile is-variable is-4">
                            <div className="column is-12-mobile is-6-desktop">
                                <div className="card_info_room_text">
                                    <div className="icon_info">
                                        <img src="images/ic_well.png" alt="" />
                                    </div>
                                    <h1 className="title_info">
                                        {locale.t('Catering')}
                                    </h1>
                                    <p className="text_info">
                                        {locale.t('CateringDetails')}
                                    </p>
                                    <div className="see_about">
                                        <Link to="/services" className="button is-more">
                                            {locale.t('seeMore')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-12-mobile is-6-desktop">
                                <div className="card_info_room">
                                    <Swiper {...Meet}>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/service/PhuruaBussabaResort_meet1.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/service/PhuruaBussabaResort_meet2.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/service/PhuruaBussabaResort_meet3.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/service/PhuruaBussabaResort_meet4.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/service/PhuruaBussabaResort_meet5.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/service/PhuruaBussabaResort_meet6.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/service/PhuruaBussabaResort_meet7.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/service/PhuruaBussabaResort_meet8.jpg" alt="" />
                                            </div>
                                        </div>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="meet_room ">
                    <div className="container">
                        <div className="icon_info">
                            <img src="images/home.png" alt="" />
                        </div>
                        <h1 className="title_meet_room ">
                            {locale.t('Meeting')}
                        </h1>
                        <Swiper  {...Card}>
                            <div >
                                <article className="cards card--5">
                                    <div className="card__img"></div>
                                    <div className="card__img--hover"></div>
                                    <div className="card__info">
                                        <h3 className="card__title"> {locale.t('meetA')}</h3>

                                    </div>
                                </article>
                            </div>
                            <div >
                                <article className="cards card--6">
                                    <div className="card__img"></div>
                                    <div className="card__img--hover"></div>
                                    <div className="card__info">
                                        <h3 className="card__title">{locale.t('meetB')}</h3>

                                    </div>
                                </article>
                            </div>
                            <div >
                                <article className="cards card--7">
                                    <div className="card__img"></div>
                                    <div className="card__img--hover"></div>
                                    <div className="card__info">
                                        <h3 className="card__title">{locale.t('meetC')}</h3>

                                    </div>
                                </article>
                            </div>
                            <div >
                                <article className="cards card--8">
                                    <div className="card__img"></div>
                                    <div className="card__img--hover"></div>
                                    <div className="card__info">
                                        <h3 className="card__title">{locale.t('meetD')}</h3>

                                    </div>
                                </article>
                            </div>
                        </Swiper>
                        <div className="see_all">
                            <Link to="/meetroom" className="button   is-more">
                                {locale.t('SeeAll')}
                            </Link>
                        </div>
                    </div>
                </section>

                <section className="info_room">
                    <div className="container">
                        <div className="columns  is-reverse-mobile is-vcentered is-multiline is-mobile is-variable is-4">
                            <div className="column is-12-mobile is-6-desktop">
                                <div className="card_info_room">
                                    <Swiper {...Meet}>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/PhuruaBussabaResort_a1.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/PhuruaBussabaResort_a2.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/PhuruaBussabaResort_a3.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/PhuruaBussabaResort_a4.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/PhuruaBussabaResort_a5.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/activities/PhuruaBussabaResort_a6.jpg" alt="" />
                                            </div>
                                        </div>

                                    </Swiper>
                                </div>
                            </div>
                            <div className="column is-12-mobile is-6-desktop">
                                <div className="card_info_room_text">
                                    <div className="icon_info">
                                        <img src="images/ic_tree.png" alt="" />
                                    </div>
                                    <h1 className="title_info">
                                        {locale.t('Activities')}
                                    </h1>
                                    <p className="text_info">
                                        {locale.t('ลานกิจกรรมขนาดใหญ่ของบุษบารีสอร์ท จะมีขนาดเกือบเท่าสนามฟุตบอล อยู่ด้านหน้าตึกอัญชัน และเรามีสนามหญ้ายาวตลอดแนวด้านหน้ารีสอร์ท ซึ่งลูกค้าสามารถใช้พื้นที่ทำกิจกรรมได้ตลอดแนวยาว 350 เมตร ')}
                                    </p>
                                    <div className="see_about">
                                        <Link to="/activities" className="button is-more">
                                            {locale.t('seeMore')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="info_room is_bg ">
                    <div className="container">
                        <div className="columns  is-vcentered is-multiline is-mobile is-variable is-4">
                            <div className="column is-12-mobile is-6-desktop">
                                <div className="card_info_room_text">
                                    <div className="icon_info">
                                        <img src="images/ic_mountain.png" alt="" />
                                    </div>
                                    <h1 className="title_info">
                                        {locale.t('Travel')}
                                    </h1>
                                    <p className="text_info">
                                        {locale.t('บริเวณใกล้เคียงยังมีสถานที่ท่องเที่ยวอื่นๆอีก เช่น รูพญานาคที่วัดโพนชัย (สถานที่จัดงานและประกอบพิธี), พิพิธภัณฑ์ผีตาโขน, พระธาตุศรีสองรัก, วัดเนรมิตวิปัสสนา, ไร่องุ่นชาโต้, ภูเขาฟูจิเมืองไทย, สวนหินผางาม อย่าลืมแวะเที่ยวอำเภอภูเรือของเราด้วย เพียงแค่ 33 กิโลเมตรจากด่านซ้าย และเดินทางต่อไปอำเภอเชียงคานได้')}
                                    </p>
                                    <div className="see_about">
                                        <Link to="/Travel" className="button is-more">
                                            {locale.t('seeMore')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-12-mobile is-6-desktop">
                                <div className="card_info_room">
                                    <Swiper {...Meet}>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/travel/travel1.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/travel/travel2.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/travel/travel3.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="card_img_info">
                                                <img src="images/travel/travel4.jpg" alt="" />
                                            </div>
                                        </div>


                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div >

        );
    }
}

const mapStateToProps = state => ({
    l: state.i18n.locale,
})

const mapDispatchToProps = dispatch => ({
    setLang: (l) => {
        dispatch(setLocale(l))
    },

})

export default connect(mapStateToProps, mapDispatchToProps)(Home);

