import React, { Component } from 'react';
import 'bulma/css/bulma.css'
import './css/style.css'
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import { setLocale } from 'react-redux-i18n'
const locale = require('react-redux-i18n').I18n


class Footer extends Component {

    render() {
        return (
            <div>

                <footer className="my_footer">
                    <div className="container">
                        <div className="columns is-multiline is-mobile is-variable is-3">
                            <div className="column is-12-mobile is-3-desktop">
                                <h1 className="title_footer">Menu</h1>
                                <ul className="link_footer">
                                    <li>
                                        <Link to="/">{locale.t('Home')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/about">{locale.t('About')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/restroom">{locale.t('Restroom')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/services">{locale.t('Catering')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/meetroom">{locale.t('Meeting')}</Link>
                                    </li>


                                    <li>
                                        <Link to="/activities">{locale.t('Activities')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/travel">{locale.t('Travel')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">{locale.t('ContactUs')}</Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="column is-12-mobile is-5-desktop">
                                <h1 className="title_footer">Contact us</h1>
                                <p>Phurua Bussaba Resort
                        </p>
                                <p>184 ม.2 ต.หนองบัว อ.ภูเรือ จ.เลย 42160<a className="map_link" href="https://goo.gl/maps/aGrSvAH4VnDVsRp5A" target="_blank">See Map</a>
                                </p>
                                <p>Tel: 042-899299, 042-899330, 082-3942619
                        </p>
                                <p>Email : bussabaresort@gmail.com , khingkharn_t@hotmail.com</p>
                                <p>Line id : bussabaresort1
                        </p>
                            </div>
                            <div className="column is-12-mobile is-4-desktop">
                                <h1 className="title_footer">Social</h1>
                                <a href="https://www.facebook.com/phuruabussaba" target="_blank" className="social_link is-inline">
                                    <img src="images/facebook.png" alt="" />
                                </a>
                                <a href="http://line.me/ti/p/~bussabaresort1" target="_blank" className="social_link is-inline">
                                    <img src="images/line.png" alt="" />
                                </a>
                                <a href="https://goo.gl/maps/aGrSvAH4VnDVsRp5A" target="_blank" className="social_link is-inline">
                                    <img src="images/google.png" alt="" />
                                </a>
                                <a href="https://m.youtube.com/channel/UCe4T-55WEZK9NilftsNzmIg?reload=9" target="_blank" className="social_link is-inline">
                                    <img src="images/youtube.png" alt="" />
                                </a>

                                <h1 className="title_footer is_margin">{locale.t('RentTitle')}</h1>
                                <p>
                                    {locale.t('RentTitleA')}
                                </p>
                                <p>
                                    {locale.t('RentTitleB')}
                                </p>
                                <p>
                                    {locale.t('RentTitleC')}
                                </p>



                            </div>
                            <div className="column is-12-mobile id-12-desktop">
                                <p className="copy">© 2019 Phurua Bussaba Resort. ALL RIGHTS RESERVED.</p>
                            </div>
                        </div>

                    </div>
                </footer>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    l: state.i18n.locale,
})

const mapDispatchToProps = dispatch => ({
    setLang: (l) => {
        dispatch(setLocale(l))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

