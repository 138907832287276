import React from 'react';
import 'bulma/css/bulma.css'
import './css/style.css'
import { connect } from 'react-redux'
import { setLocale } from 'react-redux-i18n'
import 'react-id-swiper/lib/styles/css/swiper.css'
const locale = require('react-redux-i18n').I18n

function About() {
    return (
        <div className="page_contain">
            <div className="columns is-mobile is-centered is-multiline is-mobile">
                <div className="column is-12-mobile is-9-desktop">
                    <div className="page_content">
                        <div className="icon_info">
                            <img src="images/ic_well.png" alt="" />
                        </div>
                        <h1 className="title_info">
                            {locale.t('About')}
                        </h1>
                        <p className="text_info">
                            {locale.t('About_info')}
                        </p>
                        <div className=" section_view">
                            {/* About 1 */}
                            <div className="columns   is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <div className="card_img_info">
                                        <img src="images/about/about1.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        Green resort & Eco friendly hotel
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('About1')}
                                    </p>

                                </div>
                            </div>
                            {/* About 2 */}
                            <div className="columns is-reverse-mobile is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        Welcome drink
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('About2')}
                                    </p>

                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <div className="card_img_info">
                                        <img src="images/about/about2.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                            {/* About 3 */}
                            <div className="columns   is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <div className="card_img_info">
                                        <img src="images/about/about3.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        Wifi areas
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('About3')}
                                    </p>

                                </div>
                            </div>
                            {/* About 4 */}
                            <div className="columns is-reverse-mobile is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        Eco friendly cleaners
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('About4')}
                                    </p>

                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <div className="card_img_info">
                                        <img src="images/about/about4.jpg" alt="" />
                                    </div>
                                </div>
                            </div>

                            {/* About 6 */}
                            <div className="columns   is-vcentered is-multiline is-mobile is-variable">
                                <div className="column is-12-mobile is-6-desktop">
                                    <div className="card_img_info">
                                        <img src="images/about/about5.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="column is-12-mobile is-6-desktop">
                                    <h1 className="title_info">
                                        Non-chemical vegetables
                                    </h1>
                                    <p className="text_content">
                                        {locale.t('About6')}
                                    </p>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    l: state.i18n.locale,
})

const mapDispatchToProps = dispatch => ({
    setLang: (l) => {
        dispatch(setLocale(l))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(About);

